import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { ErrorStatusEnum } from '@app/common';
import { withAuth } from '../models/functions/with-auth';
import { Layout } from '../blocks/web/layout/layout';
import { IntroHomepage } from '../blocks/web/intro/homepage/intro-homepage';
import { FeatureList } from '../blocks/web/feature-list/feature-list';
import { Cta } from '../blocks/web/cta/cta';
import { Pricing } from '../blocks/web/pricing/pricing';
import { createWebServerSideProps } from '../models/functions/create-web-server-side-props';
import { routes } from '../config/routes';
import { useRootStore } from '../providers/root-store-provider';
import { FaqTop } from '../blocks/web/faq/top/faq-top';

const IndexWeb: React.FC = () => {
    const { t } = useTranslation();
    const { alertStore, authStore, themeStore } = useRootStore();
    const router = useRouter();

    useEffect(() => {
        const handleQuery = async () => {
            // handle logout
            if (router.query.logout) {
                authStore.logout();
                await router.replace(routes.web.index);
                themeStore.setDefaultTheme();
            }

            // handle errors from server side (get server side props)
            if (router.query.status && router.query.status.length) {
                await router.push(routes.web.index);
                alertStore.setErrorMessageByStatus(router.query.status as ErrorStatusEnum);
            }
        };
        handleQuery();
    }, [router.query]);

    return (
        <Layout intro={<IntroHomepage />}>
            <Head>
                <meta property="og:type" content="website" />
                <meta property="og:url" content={`https://monery.io`} />
                <meta property="og:image" content={`https://monery.io/images/social-covers/monery.jpg`} />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={`https://monery.io`} />
                <meta property="twitter:image" content={`https://monery.io/images/social-covers/monery.jpg`} />
                <title>
                    {t('pages.homepage.meta.title')}
                    {t('meta.title.suffix')}
                </title>
                <meta name="description" content={t('pages.homepage.meta.description')} />
                <meta property="og:title" content={t('pages.homepage.meta.title')} />
                <meta property="og:description" content={t('pages.homepage.meta.description')} />
                <meta property="twitter:title" content={t('pages.homepage.meta.title')} />
                <meta property="twitter:description" content={t('pages.homepage.meta.description')} />
            </Head>
            <FeatureList />

            <FaqTop />

            <Cta titleGrey={t('pages.homepage.cta.title')} title={t('pages.homepage.cta.subtitle')} />

            <Pricing />
        </Layout>
    );
};

export const getServerSideProps = createWebServerSideProps(true);

export default withAuth(IndexWeb, false);
