import { makeAutoObservable } from 'mobx';
import { StornoReasonEnum } from '@app/common';
import type { RootStore } from './root-store';
import { StornoStepEnum } from '../enums/storno-step-enum';

export class OffboardingStore {
    rootStore: RootStore;

    active: 'cancelPremium' | 'deleteAccount' | null = null;

    step: StornoStepEnum = StornoStepEnum.Preferences;

    stornoReason: StornoReasonEnum | null = null;

    stornoReasonDescription: string = '';

    loading = false;

    cancelLoading = false;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeAutoObservable(this, {
            rootStore: false,
        });
    }

    openCancelPremium(): void {
        this.active = 'cancelPremium';
        this.rootStore.alertStore.setModalPlacement();
    }

    openDeleteAccount(): void {
        this.active = 'deleteAccount';
        this.rootStore.alertStore.setModalPlacement();
    }

    close(): void {
        this.active = null;
        this.rootStore.alertStore.setDefaultPlacement();
        this.setStep(StornoStepEnum.Preferences);
        this.setStornoReason(null);
    }

    startLoading(): void {
        this.loading = true;
    }

    stopLoading(): void {
        this.loading = false;
    }

    startCancelLoading(): void {
        this.cancelLoading = true;
    }

    stopCancelLoading(): void {
        this.cancelLoading = false;
    }

    setStep(step: StornoStepEnum): void {
        this.step = step;
    }

    setStornoReason(stornoReason: StornoReasonEnum | null): void {
        this.stornoReason = stornoReason;
        this.setStornoReasonDescription('');
    }

    setStornoReasonDescription(stornoReasonDescription: string): void {
        this.stornoReasonDescription = stornoReasonDescription;
    }

    async updateStornoReason(): Promise<void> {
        try {
            this.startLoading();

            const res = await this.rootStore.apiClient.userController.userControllerUpdateStornoReason({
                updateStornoReasonDto: {
                    stornoReason: this.stornoReason || undefined,
                    stornoReasonDescription: this.stornoReasonDescription,
                },
            });
            if (!res) {
                throw new Error();
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.stopLoading();
        }
    }

    async completeOffboarding(): Promise<void> {
        try {
            this.startCancelLoading();

            await this.updateStornoReason();
            if (this.active === 'deleteAccount') {
                await this.rootStore.authStore.deleteAccount();
            } else {
                await this.rootStore.stripeStore.cancelSubscription();
            }

            await this.rootStore.authStore.fetchUserProfile();
            this.close();
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.stopCancelLoading();
        }
    }

    async getDiscount(): Promise<void> {
        try {
            this.startLoading();

            await this.rootStore.stripeStore.setStornoDiscount();
            this.rootStore.stripeStore.startShowGotDiscountAlert();

            this.close();
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.stopLoading();
        }
    }
}
