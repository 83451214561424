import { makeAutoObservable } from 'mobx';
import {
    CurrencyEntity,
    PortfolioEntity,
    SymbolTypeEnum,
    TradeListWithSplitDto,
    TradeTypeEnum,
} from '../../../../defs/api';
import { SymbolWithExchangeMobxDto } from '../symbol/symbol-with-exchange-mobx-dto';

export class TradeListWithSplitMobxDto implements TradeListWithSplitDto {
    split: number = 1;

    id: string = '';

    symbol: SymbolWithExchangeMobxDto = new SymbolWithExchangeMobxDto();

    date: Date = new Date();

    type: TradeTypeEnum = TradeTypeEnum.Buy;

    amount = 0;

    unitPrice = 0;

    fees = 0;

    note = '';

    currency: CurrencyEntity;

    tickerId: string | null = null;

    portfolioId: string = '';

    symbolCode: string = '';

    symbolName: string = '';

    symbolType: SymbolTypeEnum = SymbolTypeEnum.Cs;

    exchangeId: string = '';

    exchangeName: string = '';

    isSelected: boolean = false;

    value: number = 0;

    referenceId: string = '';

    portfolio: PortfolioEntity;

    constructor(trade?: Partial<TradeListWithSplitMobxDto>) {
        if (trade) {
            Object.assign(this, trade);
        }
        makeAutoObservable(this);
    }

    static create(dto: TradeListWithSplitDto): TradeListWithSplitMobxDto {
        const mobxDto = new TradeListWithSplitMobxDto();
        return Object.assign(mobxDto, dto, { symbol: SymbolWithExchangeMobxDto.create(dto.symbol) });
    }

    static createFromArray(dtos: TradeListWithSplitDto[]): TradeListWithSplitMobxDto[] {
        return dtos.map((dto) => TradeListWithSplitMobxDto.create(dto));
    }

    public switchSelected(): void {
        this.isSelected = !this.isSelected;
    }

    public setSelected(selected: boolean): void {
        this.isSelected = selected;
    }
}
