import { makeAutoObservable, runInAction } from 'mobx';
import moment from 'moment';
import type { RootStore } from './root-store';
import { LookupKeyEnum } from '../../defs/api';

/**
 * Slouží pro:
 * - zpracovávání subscriptions pomocí stripe
 */
export class StripeStore {
    rootStore: RootStore;

    loading = false;

    cancelLoading = false;

    switchSubscriptionPriceLoading = false;

    lookupKey: LookupKeyEnum | null = null;

    switchSubscriptionPriceDone: boolean = false;

    showGotDiscountAlert: boolean = false;

    // prevent hooks count error in with-auth
    creatingCheckoutSession = false;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeAutoObservable(this, {
            rootStore: false,
        });
    }

    get canShowYearlyButton() {
        const isCzkMonthly = this.lookupKey === LookupKeyEnum.PremiumMonthlyCzk202411;
        const doNotCancelSubscription = !this.rootStore.authStore.userProfile?.subscriptionEndDate;

        return isCzkMonthly && doNotCancelSubscription;
    }

    get canShowYearlyMessage() {
        const isEurMonthly = this.lookupKey === LookupKeyEnum.Premium;
        const doNotCancelSubscription = !this.rootStore.authStore.userProfile?.subscriptionEndDate;

        return isEurMonthly && doNotCancelSubscription;
    }

    get canShowYearlyButtonUpgradeAtHigherPrice() {
        const isMonthlyOldPrice = this.lookupKey === LookupKeyEnum.PremiumMonthlyCzk;
        const doNotCancelSubscription = !this.rootStore.authStore.userProfile?.subscriptionEndDate;

        return isMonthlyOldPrice && doNotCancelSubscription;
    }

    startLoading(): void {
        this.loading = true;
    }

    stopLoading(): void {
        this.loading = false;
    }

    startCancelLoading(): void {
        this.cancelLoading = true;
    }

    stopCancelLoading(): void {
        this.cancelLoading = false;
    }

    startSwitchSubscriptionPriceLoading(): void {
        this.switchSubscriptionPriceLoading = true;
    }

    stopSwitchSubscriptionPriceLoading(): void {
        this.switchSubscriptionPriceLoading = false;
    }

    clearSwitchSubscriptionPriceDone(): void {
        this.switchSubscriptionPriceDone = false;
    }

    startShowGotDiscountAlert(): void {
        this.showGotDiscountAlert = true;
    }

    stopShowGotDiscountAlert(): void {
        this.showGotDiscountAlert = false;
    }

    async createCheckoutSession(lookupKey: LookupKeyEnum): Promise<void> {
        this.rootStore.cookieService.setInterestedInSubscription(null);

        try {
            this.creatingCheckoutSession = true;
            this.startLoading();
            const res = await this.rootStore.apiClient.stripeController.stripeControllerCreateCheckoutSession({
                createCheckoutSessionDto: { lookupKey },
            });
            if (res) {
                window.location.href = res.url;
            }
        } catch (e) {
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
            // eslint-disable-next-line no-console
            console.error(e);
        } finally {
            this.stopLoading();
        }
    }

    async fetchLookupKey(): Promise<void> {
        try {
            this.startLoading();
            const res = await this.rootStore.apiClient.stripeController.stripeControllerGetSubscriptionPrice();
            if (!res || !res.lookupKey) {
                return;
            }

            runInAction(() => {
                this.lookupKey = res.lookupKey as LookupKeyEnum;
            });
        } catch (e) {
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
            // eslint-disable-next-line no-console
            console.error(e);
        } finally {
            this.stopLoading();
        }
    }

    async setStornoDiscount(): Promise<void> {
        // TODO: implement set discount
    }

    async switchSubscriptionPrice(): Promise<void> {
        try {
            this.startSwitchSubscriptionPriceLoading();
            const res = await this.rootStore.apiClient.stripeController.stripeControllerSwitchSubscriptionPrice();

            if (res && res.session) {
                window.location.href = res.session.url;
            } else {
                await this.fetchLookupKey();
                this.switchSubscriptionPriceDone = true;
            }
        } catch (e) {
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
            // eslint-disable-next-line no-console
            console.error(e);
        } finally {
            this.stopSwitchSubscriptionPriceLoading();
        }
    }

    async createPortalSession(): Promise<void> {
        try {
            this.startCancelLoading();
            const res = await this.rootStore.apiClient.stripeController.stripeControllerCreatePortalSession();
            if (res) {
                window.location.href = res.url;
            }
        } catch (e) {
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
            // eslint-disable-next-line no-console
            console.error(e);
        } finally {
            this.stopCancelLoading();
        }
    }

    async cancelSubscription(): Promise<void> {
        try {
            this.startLoading();
            const res = await this.rootStore.apiClient.stripeController.stripeControllerCancelSubscription();
            runInAction(() => {
                if (this.rootStore.authStore.userProfile && res?.subscriptionEndDate) {
                    this.rootStore.authStore.userProfile.subscriptionEndDate = moment(res.subscriptionEndDate).toDate();
                }
            });
        } catch (e) {
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
            // eslint-disable-next-line no-console
            console.error(e);
        } finally {
            this.stopLoading();
        }
    }

    async reactivateSubscription(): Promise<void> {
        try {
            this.startLoading();
            const res = await this.rootStore.apiClient.stripeController.stripeControllerReactivateSubscription();
            if (res) {
                runInAction(() => {
                    if (this.rootStore.authStore.userProfile) {
                        this.rootStore.authStore.userProfile.subscriptionEndDate = null;
                    }
                });
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        } finally {
            this.stopLoading();
        }
    }
}
